import React from "react";
import {
  Button,
  Flex,
  View,
  Text,
  withAuthenticator
} from "@aws-amplify/ui-react";

const CurrentEnquiriesView = ({ enquiries, deleteEnquiry, signOut }) => {
  const headers = (
    <Flex
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      fontWeight={700}
      paddingBottom="0.5rem"
      borderBottom="1px solid #ccc"
      marginBottom="0.5rem"
    >
      <h2>Current Enquiries</h2>
      <Text as="strong" style={{ width: "10%" }}>
        First Name
      </Text>
      <Text as="strong" style={{ width: "10%" }}>
        Last Name
      </Text>
      <Text as="strong" style={{ width: "15%" }}>
        Email
      </Text>
      <Text as="strong" style={{ width: "10%" }}>
        Landline
      </Text>
      <Text as="strong" style={{ width: "10%" }}>
        Mobile
      </Text>
      <Text as="strong" style={{ width: "15%" }}>
        Best time to call
      </Text>
      <Text as="strong" style={{ width: "25%" }}>
        Comments
      </Text>
      <Text as="strong" style={{ width: "5%" }} />
    </Flex>
  );

  return (
    <View margin="3rem 0">
      {headers}
      {enquiries.map((enquiry) => (
        <Flex
          key={enquiry.id || enquiry.lastname}
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          paddingBottom="0.5rem"
          borderBottom="1px solid #ccc"
          marginBottom="0.5rem"
        >
          <Text as="span" style={{ width: "10%" }}>
            {enquiry.firstname}
          </Text>
          <Text as="span" style={{ width: "10%" }}>
            {enquiry.lastname}
          </Text>
          <Text as="span" style={{ width: "15%" }}>
            {enquiry.email}
          </Text>
          <Text as="span" style={{ width: "10%" }}>
            {enquiry.landline}
          </Text>
          <Text as="span" style={{ width: "10%" }}>
            {enquiry.mobile}
          </Text>
          <Text as="span" style={{ width: "15%" }}>
            {enquiry.besttimetocall}
          </Text>
          <Text as="span" style={{ width: "25%" }}>
            {enquiry.comments}
          </Text>
          <Button
            variation="link"
            onClick={() => deleteEnquiry(enquiry)}
            style={{ width: "5%" }}
          >
            X
          </Button>
        </Flex>
      ))}
      <Button onClick={signOut}>Sign Out</Button>
    </View>
  );
};

export default withAuthenticator(CurrentEnquiriesView);
