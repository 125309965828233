import React, { useState, useEffect, useContext } from "react";
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';


const CONTACT_US_INDEX = 1;
const SERVICES_INDEX = 2;
const ABOUT_US_INDEX = 3;


const Jobs = () => {



  return (
        <div>
            <Card className="clear-card center-card borderless">
                <Card.Title className="ac-card-title">Cleaner Jobs</Card.Title>
                <Card.Text className="title-text text-left">
                HOUSE CLEANING JOBS IN WIGAN & Nearby Areas - We are currently recruiting New Cleaners. Ring Now on 01942 713111 or complete the form below.
                </Card.Text>
            </Card>
        </div>
    );
};


export default Jobs;