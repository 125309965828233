import Carousel from 'react-bootstrap/Carousel';

function Testimonials() {
  return (
    
    <Carousel variant='dark'>
      <Carousel.Item>
            <div className="d-block w-100">
                <p className='testimonial-text'>
                    "Just wanted to drop you a line to say we are absolutely delighted with Verity..  it's like coming home to a hotel!
                    Many thanks again"</p>
                <p className='testimonial-name'>Michelle C</p>
                <p className='testimonial-address'>Wigan</p>
            </div>
        </Carousel.Item>
        <Carousel.Item>
            <div className="d-block w-100">
                <p className='testimonial-text'>
                "Dear Carole,     
                Thank you for supplying me with an excellent cleaning service 
                   ...  I have been delighted with Adele and Diane"</p>
                <p className='testimonial-name'>Ceri</p>
                <p className='testimonial-address'>Upholland</p>
            </div>
        </Carousel.Item>
        <Carousel.Item>
            <div className="d-block w-100">
                <p className='testimonial-text'>
                "Just wanted to let you know how pleased I am with Kim my cleaner. She is so good, always does exactly what is needed and a fab job.
                So reliable.
                Credit to your company.
                Thanks"</p>
                <p className='testimonial-name'>Tricia C</p>
                <p className='testimonial-address'>Billinge</p>
            </div>
        </Carousel.Item>
    </Carousel>
    // <Carousel fade>
    //     <Carousel.Item>
    //     <img
    //       className="d-block w-100"
    //       src="holder.js/800x400?text=First slide&bg=373940"
    //       alt="First slide"
    //     />
    //     <Carousel.Caption>
    //       <h3>First slide label</h3>
    //       <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p>
    //     </Carousel.Caption>
    //   </Carousel.Item>
    //   <Carousel.Item>
    //     <Carousel.Caption>
    //     "Just wanted to drop you a line to say we are absolutely delighted with Verity..  it's like coming home to a hotel!
    //     Many thanks again
    //     "
    //     <p>Michelle C</p>
    //     <p>Wigan</p>
    //     </Carousel.Caption>
    //   </Carousel.Item>
    //   <Carousel.Item>
    //     <Carousel.Caption>
    //     "Dear Carole,
    //     Thank you for supplying me with an excellent cleaning service ...
    //     I have been delighted with Adele and Diane"
    //     <p>Ceri</p>
    //     <p>Upholland</p>
    //     </Carousel.Caption>
    //   </Carousel.Item>
    //   <Carousel.Item>
    //     <Carousel.Caption>
    //     "Just wanted to let you know how pleased I am with Kim my cleaner. She is so good, always does exactly what is needed and a fab job.
    //     So reliable.
    //     Credit to your company.
    //     Thanks"
    //     <p>Tricia C</p>
    //     <p>Billinge</p>
    //     </Carousel.Caption>
    //   </Carousel.Item>
    //   <Carousel.Item>
    //     <Carousel.Caption>
    //     "Just to say that Clare started today and has done a great job of tackling this nightmare!
    //     She is also a very lovely person, so very happy and comfortable having her in my home.
    //     The dogs like her too!
    //     She's happy to get on with it and I'm happy to leave her to it!

    //     Anyway, Clare is a credit to your company, thanks again"
    //     <p>Caroline B</p>
    //     <p>Newton-Le-Willows</p>
    //     </Carousel.Caption>
    //   </Carousel.Item>
    //   <Carousel.Item>
    //     <Carousel.Caption>
    //     "I am so happy with my cleaner, Donna, and whenever she is on holiday the cover cleaners you have sent have been excellent also!
    //     Thank you for your hard work. I am very happy to recommend you."
    //     <p>Jim Wilson</p>
    //     <p>Pemberton, Wigan</p>
    //     </Carousel.Caption>
    //   </Carousel.Item>
    //   <Carousel.Item>
    //     <Carousel.Caption>
    //     "May I take this opportunity to say I am well satisfied with the ladies you send to my house. "
    //     <p>Marian B</p>
    //     <p>Pemberton, Wigan</p>
    //     </Carousel.Caption>
    //   </Carousel.Item>
    //   <Carousel.Item>
    //     <Carousel.Caption>
    //     "I'd just like to take this opportunity to say how lovely and how reassuring you were when we met. It just felt like a weight had been lifted from us.
    //     Joan, the cleaning lady rang and she was delightful. It just felt "safe", which is so important.
    //     She was lovely to talk to and we just felt reassured that mum will be in safe hands.
    //     "
    //     <p>Gill C</p>
    //     <p>Shevington, Wigan</p>
    //     </Carousel.Caption>
    //   </Carousel.Item>
    //   <Carousel.Item>
    //     <Carousel.Caption>
    //     "I just wanted to say thank you for your thoughtfulness.
    //     Mum is very vulnerable and it is reassuring that the ladies who go to her house are not just anonymous people.
    //     Thanks again"
    //     <p>Eileen R</p>
    //     <p>Standish, Wigan</p>
    //     </Carousel.Caption>
    //   </Carousel.Item>
    //   <Carousel.Item>
    //     <Carousel.Caption>
    //     "I cannot begin to say how invaluable Joan has been for the last 3 years.
    //     She is reliable, thorough and has simply just got on with it.
    //     It's been just what I needed.
    //     May I take this opportunity to say a very sincere thank you to you and your staff.
    //     I I would have no hesitation in recommending your service.
    //     Indeed I know of at least 3 people whom I have spoken to and who are now your clients."
    //     <p>Gill C</p>
    //     <p>Shevington, Wigan</p>
    //     </Carousel.Caption>
    //   </Carousel.Item>
    // </Carousel>
  );
}

export default Testimonials;