import React, { useState } from "react";
import {
  Button,
  Flex,
  TextField,
  TextAreaField,
  SelectField,
  View,
} from "@aws-amplify/ui-react";

const ContactView = ({ createEnquiry }) => {
  const [showThankYou, setShowThankYou] = useState(false);

  const handleFormSubmit = async (event) => {
    event.preventDefault();
    try {
      await createEnquiry(event);
      setShowThankYou(true);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div class="container mx-auto">
      <View as="form" margin="3rem 0" onSubmit={handleFormSubmit}>
        <img src={require("./images/AllClean_logo.jpg")} alt="logo" style={{ height: "50%" }} />
        <Flex gap="1rem" alignItems="center" justifyContent="center">
          <Flex direction="column" gap="1rem">
            <p className="ac-card-title orange-font">Contact Us</p>
            <Flex gap="1rem">
              <TextField name="firstname" placeholder="First Name" label="First Name" required style={{ flex: "1" }} />
              <TextField name="lastname" placeholder="Last Name" label="Last Name" required style={{ flex: "1" }} />
            </Flex>
            <TextField type="email" name="email" placeholder="Email" label="Email" required />
            <Flex gap="1rem">
              <TextField name="landline" placeholder="Landline" label="Landline" style={{ flex: "1" }} />
              <TextField name="mobile" placeholder="Mobile" label="Mobile" required style={{ flex: "1" }} />
            </Flex>
            <SelectField name="besttimetocall" placeholder="Best time to call you" required>
              <option value="Morning">Morning</option>
              <option value="Afternoon">Afternoon</option>
              <option value="Evening">Evening</option>
              <option value="Anytime">Anytime</option>
            </SelectField>
            <TextAreaField name="comments" 
              placeholder="Comments" 
              label="Comments" 
              required 
              style={{ minHeight: "6rem" }} />
            {showThankYou ? (
              <p style={{ textAlign: "center", color: "green" }}>Thank you for contacting us!</p>
            ) : (
              <Button className="button-text" type="submit" variation="primary">
                Please Contact Me
              </Button>
            )}
          </Flex>
        </Flex>
      </View>
   </div>
  );
};

export default ContactView;
