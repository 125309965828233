import React from "react";
import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';

import './App.css';

const FAQ = () => {
  return (
    <Card className="clear-card center-card borderless">
        <Card.Title className="ac-card-title margin-top margin-bottom bold">
            Frequently Asked Questions
        </Card.Title>
        <Card.Text className="text-left orange-font margin-top font-medium">
            <Accordion>
                <Accordion.Item eventKey="0"  className="no-border">
                    <Accordion.Header className='large-text transparent orange-font bold'>How many hours of cleaning will I need?</Accordion.Header>
                    <Accordion.Body>
                        <p className="my-list">Great question!</p> 
                        <p className="my-list">We understand that you may not always be sure of the exact length of time needed for your clean.</p>
                        <p className="my-list">By visiting you personally at your convenience we are able to understand your exact requirements and situation and are able to advise you more accurately.</p>
                        <p className="my-list">If you are on a budget we will discuss how we may best serve you, subject to a minimum of 2 hours of cleaning per visit.</p>
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1"  className="no-border">
                    <Accordion.Header className='large-text transparent orange-font bold'>Do you supply the cleaning materials?</Accordion.Header>
                    <Accordion.Body>
                        <p className="my-list">No we don't. We will use your cleaning materials, vacuum cleaner, mops etc. This enables us to keep our costs to a minimum which we would otherwise have to pass on to you by charging a higher hourly rate.</p>
                        <p className="my-list">Most clients tell us they prefer us to use their products as it feels 'safer'. </p>
                        <p className="my-list">They know exactly which products are being used in their home and there is then no risk of cross contamination  between houses.</p>
                    </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey="2"  className="no-border">
                    <Accordion.Header className='large-text transparent orange-font bold'>Can you also do my ironing?</Accordion.Header>
                    <Accordion.Body>
                        <p className="my-list">Yes with pleasure!</p>
                        <p className="my-list">Our cleaners are happy to iron as well as clean for you if you wish.</p>
                        <p className="my-list">Many clients find this to be more cost effective and less expensive than using an ironing service.</p>
                    </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey="3"  className="no-border">
                    <Accordion.Header className='large-text transparent orange-font bold'>Will I get to meet my cleaner first?</Accordion.Header>
                    <Accordion.Body>
                        <p className="my-list">Most certainly.</p>
                        <p className="my-list">Prior to starting work for you, your cleaner will contact you by phone to introduce herself.</p>
                        <p className="my-list">At this time she will arrange to come and see you at a time convenient for you. You will then be able to meet with her, ask any questions you wish, and give her a key to the property also if it is needed.</p>
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="4"  className="no-border">
                    <Accordion.Header className='large-text transparent orange-font bold'>Do I need to be at home on my cleaning day?</Accordion.Header>
                    <Accordion.Body>
                        <p className="my-list">This is entirely your choice.</p>
                        <p className="my-list">Our cleaners are used to being key holders as the majority of our clients are out at work on their cleaning day.</p>
                        <p className="my-list">However, if you prefer to be there on the day that is absolutely fine with us also.</p>
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="5"  className="no-border">
                    <Accordion.Header className='large-text transparent orange-font bold'>Do you supply the cleaning materials?</Accordion.Header>
                    <Accordion.Body>
                        <p className="my-list">With great care! </p>
                        <p className="my-list">Only a very small percentage of applicants are successful in getting past this first stage.</p>
                        <p className="my-list">If they are successful in the first stage we then arrange to interview them in their own home.</p>
                        <p className="my-list">They then have to pass a second stage in depth interview during which we will also check ID and proof of address.</p>
                        <p className="my-list">If they pass this successfully then at the third stage we will contact past employers and obtain written references and sometimes character references also.</p>
                        <p className="my-list">At the end of this third stage process and then only if we are entirely satisfied do we enrol them on our database.</p>
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="6"  className="no-border">
                    <Accordion.Header className='large-text transparent orange-font bold'>Do you do 'one-off' spring cleans or end of tenancy cleans?</Accordion.Header>
                    <Accordion.Body>
                        <p className="my-list">Yes. We also specialise in one off spring cleans and end of tenancy cleaning, either instead of or as well as our regular weekly or fortnightly cleaning service.</p>
                        <p className="my-list">For one off cleans we will supply all cleaning materials and equipment necessary to complete the work.</p>
                        <p className="my-list">For a quote please give us a call.</p>
                    </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey="7"  className="no-border">
                    <Accordion.Header className='large-text transparent orange-font bold'>What if my cleaner is sick or on holiday?</Accordion.Header>
                    <Accordion.Body>
                        <p className="my-list">Your cleaner will inform you if she is to be away on holiday, and she will also inform you if she is ill on the day the clean is due. </p>
                        <p className="my-list">If you wish us to send a replacement cleaner whilst she is off, simply telephone our office and we make the arrangements. </p>
                        <p className="my-list">We never automatically send a replacement without your consent as many of our clients would not wish us to do this and would rather either miss a clean or make other arrangements with their regular cleaner if they are able to.</p>
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="8"  className="no-border">
                    <Accordion.Header className='large-text transparent orange-font bold'>Can I change my cleaner if I am not happy?</Accordion.Header>
                    <Accordion.Body>
                        <p className="my-list">It is imperative to us that you are 100% happy with your cleaner.</p>
                        <p className="my-list">In the unlikely event that you are not satisfied for any reason, simply contact us and we shall arrange a replacement.</p>
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="9"  className="no-border">
                    <Accordion.Header className='large-text transparent orange-font bold'>Are you insured for accidental damage?</Accordion.Header>
                    <Accordion.Body>
                        <p className="my-list">Yes. For your peace of mind we have public liability insurance cover of up to £2 million, subject to our terms & conditions and a £100 excess on the policy.</p>
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
        </Card.Text>
    </Card>
  );
};

export default FAQ;
