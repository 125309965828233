import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { API } from "aws-amplify";
import {
  Flex, Heading, View, Image,
  Tabs, TabItem, useTheme
} from "@aws-amplify/ui-react";
import { listEnquiries } from "./graphql/queries";
import {
  createEnquiry as createEnquiryMutation,
  deleteEnquiry as deleteEnquiryMutation,
} from "./graphql/mutations";
import ContactView from "./ContactUs"
import CurrentEnquiriesView from "./CurrentEnquiries";
import HomeView from "./Home";
import CollapsibleNavbar from './CollapsibleNavbar';
import Card from 'react-bootstrap/Card';

import "@aws-amplify/ui-react/styles.css";
import "./App.css";
import FAQ from "./FAQ";
import OurServices from "./OurServices";
import AboutUs from "./AboutUs";
import Jobs from "./Jobs";
import { useLocation } from "react-router-dom";
import Footer from "./Footer";
import Terms from "./Terms";
import Privacy from "./Privacy";

const HOME_INDEX = 1;

const App = () => {
  const [enquiries, setEnquiries] = useState([]);
  const [selectedTab, setSelectedTab] = useState(HOME_INDEX);
  const { tokens } = useTheme();
  const location = useLocation();
  const isAdmin = location.search.includes("admin");
  
  useEffect(() => {
    fetchEnquiries();
  }, []);

  async function fetchEnquiries() {
    const apiData = await API.graphql({ query: listEnquiries });
    const enquiriesFromAPI = apiData.data.listEnquiries.items;
    const sortedEnquiries = enquiriesFromAPI.sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt));
    setEnquiries(sortedEnquiries);
  }
  

  async function createEnquiry(event) {
    try {
      event.preventDefault();
      const form = new FormData(event.target);
      const data = {
        firstname: form.get("firstname"),
        lastname: form.get("lastname"),
        email: form.get("email"),
        landline: form.get("landline"),
        mobile: form.get("mobile"),
        besttimetocall: form.get("besttimetocall"),
        comments: form.get("comments"),
      };
      await API.graphql({
        query: createEnquiryMutation,
        variables: { input: data },
      });
      fetchEnquiries();
      event.target.reset();
    } catch (error) {
      console.error(error);
      return false;
    }
    return true;
  }

  async function deleteEnquiry({ id }) {
    const newEnquiries = enquiries.filter((enquiry) => enquiry.id !== id);
    setEnquiries(newEnquiries);
    await API.graphql({
      query: deleteEnquiryMutation,
      variables: { input: { id } },
    });
  }

  return (

    <Flex
      direction="column"
      justifyContent="flex-start"
      alignItems="stretch"
      alignContent="flex-start"
      wrap="nowrap"
      gap="0rem"
      >
      <Card className="blue-card borderless">
        <Card.Text className="font-normal">
        AllClean Domestic Cleaning Agency .....    Your Local House cleaners in Wigan and Nearby Areas ..... Call us on 01942 713111
        </Card.Text>
      </Card>
      <CollapsibleNavbar/>
        <Routes>
          <Route exact path="/" element={<HomeView  />} />
          <Route path="/defaultsite" element={<HomeView />} />
          <Route path="/ourservices" element={<OurServices />} />
          <Route path="/about" element={<AboutUs/>} />
          <Route path="/contact" element={<ContactView 
              createEnquiry={createEnquiry}
            />} />
          <Route path="/faq" element={<FAQ/>} />
          <Route path="/jobs" element={<Jobs/>} />
          <Route path="/terms" element={<Terms/>} />
          <Route path="/privacy" element={<Privacy/>} />
          <Route path="/enquiries" element={<CurrentEnquiriesView
                enquiries={enquiries}
                deleteEnquiry={deleteEnquiry}
                />} />
      </Routes>

      <Footer/>

    </Flex>
  );
};

export default App;