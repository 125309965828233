import React, { useState } from 'react';
import { Navbar, Nav } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useLocation } from "react-router-dom";

import allCleanLogo from './images/AllClean_logo.ico';

function CollapsibleNavbar() {
  const [expanded, setExpanded] = useState(false);
  const location = useLocation();
  const isAdmin = location.search.includes("admin");

  const toggleExpanded = () => {
    setExpanded(!expanded);
  }

  const handleNavItemClick = () => {
    setExpanded(false);
  }

  return (
    <div className="container mx-auto">
      <Navbar bg="white" expand="lg" expanded={expanded} className="card-text orange-font">
        <Navbar.Brand href="#" >
          <img src={allCleanLogo} alt="AllClean logo" height={75} width={75} />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" onClick={toggleExpanded} />
        <Navbar.Collapse id="basic-navbar-nav" className="justify-content-center">
          <Nav className="mr-auto navbar-nav">
            <Nav.Link as={Link} to="/" onClick={handleNavItemClick}>Home</Nav.Link>
            <Nav.Link as={Link} to="/ourservices" onClick={handleNavItemClick}>Our Services</Nav.Link>
            <Nav.Link as={Link} to="/about" onClick={handleNavItemClick}>About Us</Nav.Link>
            <Nav.Link as={Link} to="/contact" onClick={handleNavItemClick}>Contact Us</Nav.Link>
            <Nav.Link as={Link} to="/faq" onClick={handleNavItemClick}>FAQ</Nav.Link>
            <Nav.Link as={Link} to="/jobs" onClick={handleNavItemClick}>Jobs</Nav.Link>
            {isAdmin && (
              <Nav.Link as={Link} to="/enquiries" onClick={handleNavItemClick}>Enquiries</Nav.Link>
            )}
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </div>
  );
}

export default CollapsibleNavbar;
