import React, { useState, useEffect, useContext } from "react";
import Card from 'react-bootstrap/Card';


const CONTACT_US_INDEX = 1;
const SERVICES_INDEX = 2;
const ABOUT_US_INDEX = 3;


const AboutUs = ({setSelectedTab}) => {

  return (
        <div class="container mx-auto w-2/3">
            <Card className="clear-card center-card borderless">
                <Card.Title className="title-text orange-font bold">Who are we?</Card.Title>
                <Card.Text className="title-text text-left">
                    AllClean are a family run House Cleaning Agency in Wigan & nearby areas and were established in 2004 when their owner realised just how many people were in need of a local domestic house cleaning service.
                    As the mother of 5 (now grown up) children she had found it necessary to employ house cleaners in the past and had also found out how difficult and time consuming it was to get the "right" cleaner.
                </Card.Text>
            </Card>
            <Card className="clear-card center-card borderless">
                <Card.Title className="title-text orange-font bold">Time consuming</Card.Title>
                <Card.Text className="title-text text-left">
                    Advertising, vetting and recruiting your own house cleaner can both stressful and time consuming, and when you are trusting someone to have a key to your home it's imperative to get it right!
                    If for some reason it doesn't work out as you had hoped you also have to fire them yourself and start the whole process over!
                </Card.Text>
            </Card>
            <Card className="clear-card center-card borderless">
                <Card.Title className="title-text orange-font bold">Good News! - It Doesn't Have to Be Like That!</Card.Title>
                <Card.Text className="title-text text-left">
                If You Are Looking for Reliable, Affordable, Trustworthy Domestic House Cleaners in Wigan & nearby areas then you need look no further!
                </Card.Text>
            </Card>
            <Card className="clear-card center-card borderless">
                <Card.Title className="title-text orange-font bold">AllClean are your Local Domestic Cleaning Service in Wigan & Nearby Areas</Card.Title>
                <Card.Text className="title-text text-left">
                    We are not part of a large franchise organisation and you will never be put through to a call centre when you ring.
                    Once you become a client of AllClean you are not just a number in a filing cabinet. Whoever takes your call will know exactly who you are and will be ready to listen and help with your enquiry.
                </Card.Text>
                <Card.Footer className="title-text orange-font">Call AllClean Now on 01942 713111 for a Fast, Friendly, No Obligation Quote!</Card.Footer>
            </Card>
        </div>
    );
};


export default AboutUs;