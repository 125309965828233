import React, { useState, useEffect, useContext } from "react";
import Card from 'react-bootstrap/Card';


const Terms = () => {

  return (
    <div class="container">
    <div class="row">
      <div class="col-sm-10">
        <div class="panel">
          <div class="panel__body fluid-video">
            <h1>TERMS OF USE</h1>
<p>PLEASE READ THESE TERMS OF USE CAREFULLY BEFORE USING THIS WEBSITE. By using this website, you signify your consent to these terms of use. If you do not agree to these Terms of Use, please do not use the website.</p>
<p>Your access to and use of this website, as well as all related websites operated by&nbsp;A11Clean Ltd trading as, and hereafter referred to as 'AllClean' &ndash;&nbsp; (which includes https://www.allclean.cleaning, among others) (collectively the &ldquo;Site&rdquo;) is subject to the following terms and conditions (&ldquo;Terms of Use&rdquo;) and all applicable laws. By accessing and browsing the Site, you accept, without limitation or qualification, the Terms of Use and acknowledge that any other agreements between you and the Site are superseded and of no force or effect:</p>
<div class="page" title="Page 1">
<div class="layoutArea">
<div class="column">
<p><strong>1. Definitions&nbsp;</strong></p>
<p>A. For the purpose of these terms and conditions, the customer shall be referred to as the &lsquo;client(s)&rsquo; and AllClean as the &lsquo;introductory agency&rsquo;. The term &lsquo;client(s)&rsquo; shall be deemed to include the client&rsquo;s spouse or partner or, in the case of a company, any authorised representative of that company. Also, if applicable registered cleaners introduced to the client may undertake varied roles for the client, but for the purpose of these terms and conditions and all other communications will be referred to as the &lsquo;cleaner(s)&rsquo;.</p>
<p>B. The introductory agency reserves the right to amend these terms and conditions if necessary. An up to date copy is always posted on the AllClean&nbsp;website.</p>
<p>C. The introductory agency shall introduce a cleaner to the client from its database of approved cleaners, on and subject to these terms and conditions.</p>
<p><strong>2. Payment</strong></p>
<p>A.&nbsp;For regular cleaning, the client(s) shall arrange a recurring card payment, standing order or similar regular payment method as requested by the introductory agency authorising&nbsp;payment to be made monthly in advance for the service subscription. Such payments&nbsp;shall continue until the agency agreement is terminated in accordance with clause 5 of these terms and conditions.</p>
<p>B.&nbsp;<span>For non-domestic clients where monthly invoicing terms have been agreed, the client shall pay the invoice for the service subscription on those terms.</span></p>
<p>C.&nbsp;For one-off cleaning, the client(s) shall arrange a direct card payment or bank transfer as requested by the introductory agency for the agency fee PRIOR to the cleaning taking place. This agreement shall be deemed to be terminated immediately following the one-off clean and the notice referred to in Clause 5C will not apply.</p>
<p>D.&nbsp;The introductory agency will not refund any advance payment upon receipt of termination notice from the client(s). No refunds will be given during the notice period. If a client(s) declines the service at any point during the contract, no refunds will be made. This includes client holidays and cleaner absence, where the client declines the offer of a cover cleaner. In addition, should the client cancel a one-off clean with less than 24 hours notice, the agency fee will not be refunded.</p>
<p>E. If, at any point, a cleaner(s) is required for more regular hours than originally agreed, a revised subscription will be calculated and confirmed to the client.</p>
<p><strong>3. Introductory Agency Insurance and Liability</strong></p>
<p>A. AlClean&nbsp;take great care when interviewing, vetting and reference checking cleaners to add to our database and although our experience tells us that they very infrequently have accidents in our clients homes, in order to give our clients peace of mind, we carry comprehensive public liability insurance of at least &pound;1m. A copy of the certificate of insurance is available on request.</p>
<p>B.&nbsp;The client(s) shall not be covered by the insurance maintained by the introductory agency if the cleaner is not introduced by the introductory agency and/or the terms of this agreement are otherwise breached.</p>
<p>C.&nbsp;The introductory agency shall not be liable for any consequential or indirect losses incurred by client(s) as a result of any failure of a cleaner to comply with his or her contractual obligations for whatever reason.&nbsp;</p>
<p>D.&nbsp;The ownership and responsibility for the return of client(s) keys remains between the client(s) and the cleaner(s).</p>
<p>E.&nbsp;As with most insurance policies, there is an excess for each and every claim. The client accepts responsibility for the first &pound;100 of this excess. This allows us to keep overall costs low and avoids claims for minor accidental damage that can occasionally occur during the course of cleaning.</p>
<p>F.&nbsp;Force majeure &amp; jurisdiction &ndash; Neither party shall be liable for any failure in performance of any of its obligations under this agreement caused by factors outside its control. English Law shall cover this agreement and the parties consent to the exclusive jurisdiction of the English courts in all matters regarding</p>
</div>
</div>
</div>
<div class="page" title="Page 2">
<div class="layoutArea">
<div class="column">
<p><strong>4. Client(s) obligations</strong></p>
<p>A.&nbsp;The cleaner is introduced to the client(s) as a self-employed cleaner and the client(s) shall retain the cleaner only under a contract for service.&nbsp;</p>
<p>B.&nbsp;The client(s) shall agree with the cleaner directly what services are to be performed by the cleaner subject to the limitations within both the cleaner's and the clients's contract with the introductory agency.</p>
<p>C.&nbsp;The client(s) shall pay the cleaner(s) directly at the agreed hourly rate for the hours worked.</p>
<p>D. The client shall contact the introductory agency directly if a cover cleaner is required due to the regular cleaner&rsquo;s illness or holiday. Cover cleaners are not automatically supplied, as not all clients require them.</p>
<p>E.&nbsp;The client(s) shall bear the cost of recovery of agency fees in the event of non-payment.</p>
<p><strong>5. Termination</strong></p>
<p>A.&nbsp;The introductory agency may terminate the agency agreement by giving one month&rsquo;s notice to the client(s) in writing.</p>
<p>B.&nbsp;The introductory agency may terminate the agency agreement with immediate effect, if it decides in its absolute discretion; it is no longer able to provide the service.</p>
<p>C.&nbsp;The client(s) may terminate the agency agreement only by giving one month&rsquo;s notice to the introductory agency in writing.</p>
<p>D.&nbsp;For a period of eighteen months following termination of the client/agency agreement the client(s) shall not engage whether directly or indirectly as an employee, agent or otherwise any cleaner who has been introduced by the introductory agency to the client(s). If the client(s) so engage(s) a cleaner, then a recruitment fee of twelve times the monthly agency fee is payable.</p>
<p>E.&nbsp;In the event of the client(s) cancelling the standing order mandate without giving one month&rsquo;s notice to the introductory agency in writing, the introductory agency may charge for any reasonable expenses incurred in addition to any outstanding payment due. It is the client(s) responsibility to ensure that any standing order mandate paying the introductory agency fee is cancelled through the bank of the client(s) upon termination of the agreement with the introductory agency. Payments received after the notice period will be returned but will incur an administration fee of&nbsp;<span>&pound;5.</span></p>
<p><strong>6. General</strong></p>
<p>If any provision of these terms and conditions shall be found to be void, invalid or unenforceable, whether by a court of law, agreements of the parties or otherwise, the remainder of this agreement shall remain in full force and effect notwithstanding any such invalidity, illegality or unenforceability but the provision in question will either be amended so as to make it valid, legal and enforceable or in the event that this is not possible, deleted.</p>
</div>
</div>
</div>
<p>&nbsp;</p>
          </div>
        </div>
      </div>
    </div>
  </div>
    );
};


export default Terms;