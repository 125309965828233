/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createEnquiry = /* GraphQL */ `
  mutation CreateEnquiry(
    $input: CreateEnquiryInput!
    $condition: ModelEnquiryConditionInput
  ) {
    createEnquiry(input: $input, condition: $condition) {
      id
      firstname
      lastname
      email
      landline
      mobile
      besttimetocall
      comments
      createdAt
      updatedAt
    }
  }
`;
export const updateEnquiry = /* GraphQL */ `
  mutation UpdateEnquiry(
    $input: UpdateEnquiryInput!
    $condition: ModelEnquiryConditionInput
  ) {
    updateEnquiry(input: $input, condition: $condition) {
      id
      firstname
      lastname
      email
      landline
      mobile
      besttimetocall
      comments
      createdAt
      updatedAt
    }
  }
`;
export const deleteEnquiry = /* GraphQL */ `
  mutation DeleteEnquiry(
    $input: DeleteEnquiryInput!
    $condition: ModelEnquiryConditionInput
  ) {
    deleteEnquiry(input: $input, condition: $condition) {
      id
      firstname
      lastname
      email
      landline
      mobile
      besttimetocall
      comments
      createdAt
      updatedAt
    }
  }
`;
