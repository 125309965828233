import React, { useState, useEffect, useContext } from "react";


const Privacy = () => {

  return (
    <div class="container">
    <div class="row">
      <div class="col-sm-10">
        <div class="panel">
          <div class="panel__body fluid-video">
            <h1 class="page__title">Privacy Policy</h1>
            <p>AllClean are committed to protecting your privacy. We will only use the information that we collect about you lawfully (in accordance with the Data Protection Act 1998 and the General Data Protection Regulation 2018). We collect information about you for two reasons: firstly, to process your enquiry and secondly, to provide you with the best possible service.</p>
<p>We will give you the chance to refuse any marketing email from us in the future.</p>
<p>We may pass your basic contact details (name, email address) directly to a third party payment processor to facilitate payments for our service. The payment processors that we use have comprehensive privacy policies of their own which can be accessed by clicking on the appropriate link below:</p>
<p><a href="https://stripe.com/gb/privacy">Stripe</a></p>
<p>The type of information we will collect about you includes:</p>
<p>We will never collect sensitive information about you without your explicit consent. The information we hold will be accurate and up to date. You can check the information that we hold about you by emailing us. If you find any inaccuracies we will delete or correct it promptly. The personal information that we hold will be held securely in accordance with our internal security policy and the law.&nbsp;</p>
<p><strong>Cookies Policy</strong></p>
<p><strong>What are cookies?</strong></p>
<p>Cookies are information files stored on your computer, tablet or smartphone that help websites remember who you are and information about your visit. Cookies can help to display the information on a website in a way that matches your interests. Most websites use cookies.</p>
<p><strong>What cookies are used on this website?</strong></p>
<p>AllClean do not place cookies on our website.</p>
<p>However there may be cookies placed by companies such as Google, the website developer, or 3<sup>rd</sup> party integrations.</p>
<p>If you have any questions/comments about privacy, you should contact us.</p>
<p>&nbsp;</p>
          </div>
        </div>
      </div>
    </div>
  </div>
    );
};


export default Privacy;