
import React from "react";
import { useNavigate } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import CardGroup from 'react-bootstrap/CardGroup';
import Testimonials from "./Testimonials";
import FAQ from "./FAQ";
import dontLeaveToChance from './images/dontleavetochance.jpg';
import takeweightoff from './images/takeweightoff.jpg';


const SERVICES = 'ourservices';
const ABOUT_US = 'about';
const CONTACT_US = 'contact';

const HomeView = () => {
  
  const navigate = useNavigate();      
  
    return (
        <div className="tab-content" style={{ width: '100%' }}>
            <Card className="bg-dark text-white borderless">
              <Card.Img src={require('./images/sink.jpg')} alt="Card image" style={{maxHeight: '50vh', objectFit: 'cover', minHeight: '12rem'}} />
              <Card.ImgOverlay className="d-flex align-items-center justify-content-center">
                <Button  
                  onClick={() => navigate(`/${CONTACT_US}`)}
                  variant="primary"
                  className="orange-button font-black bold large-text">AllClean Domestic House Cleaning Service in Wigan and Nearby Areas - 01942 713111</Button>
              </Card.ImgOverlay>
            </Card>
            <Card className="orange-card borderless">
              <Card.Text/>
            </Card>
            <Card className="grey-card center-card borderless">
              <Card.Text className="borderless card-text">
                Cleanliness in the home is of utmost importance and <em>is even more vital</em> during these times. &nbsp;
                <a href={`/${CONTACT_US}`} class="orange-font bold">
                  AllClean are open for business</a> whilst keeping your safety and that of our cleaners paramount at all times.
              </Card.Text>
            </Card>
            <div class="container mx-auto w-2/3 card-space">
              <CardGroup className="borderless">
                <Card className="borderless">
                  <Card.Body>
                    <Card.Title className="card-title">
                      <Button  
                        onClick={() => navigate(`/${SERVICES}`)}
                        className="clear-button orange-font bold large-text">Our Services</Button>
                    </Card.Title>
                    <Card.Text className="card-text">
                      Regular weekly or fortnightly house cleaning in Wigan & nearby areas plus "one off", end of tenancy or builders cleans.
                    </Card.Text>
                  </Card.Body>
                  <Card.Footer className="borderless transparent">
                      <Button  
                        onClick={() => navigate(`/${SERVICES}`)}
                        variant="primary"
                        className="blue-button button-text bold">OUR SERVICES</Button>
                    </Card.Footer>
                </Card>
                <Card className="borderless">
                  <Card.Body>
                    <Card.Title className="card-title">
                      <Button  
                        onClick={() => navigate(`/${ABOUT_US}`)}
                        className="clear-button orange-font bold large-text">Why Choose AllClean?</Button>
                    </Card.Title>
                    <Card.Text className="card-text">
                      AllClean Domestic House Cleaning in Wigan & nearby areas are a family run business with over 15 years of experience in the industry.
                    </Card.Text>
                  </Card.Body>
                  <Card.Footer className="borderless transparent">
                    <Button  
                      onClick={() => navigate(`/${ABOUT_US}`)}
                      variant="primary"
                      className="blue-button button-text bold">ABOUT US</Button>
                  </Card.Footer>
                </Card>
                <Card className="borderless">
                  <Card.Body>
                    <Card.Title className="card-title">
                      <Button  
                        onClick={() => navigate(`/${CONTACT_US}`)}
                        className="clear-button orange-font bold large-text">Get a Quote</Button>
                    </Card.Title>
                    <Card.Text className="card-text">
                    We will visit you at a time to suit you to find out your individual requirements and answer your questions.
                    </Card.Text>
                  </Card.Body>
                  <Card.Footer className="borderless transparent">
                    <Button  
                      onClick={() => navigate(`/${CONTACT_US}`)}
                      variant="primary"
                      className="blue-button button-text bold">CONTACT US</Button>
                  </Card.Footer>
                </Card>
              </CardGroup>
            </div>        
            <Card className="borderless center-card card-text">
              <Card.Text className="borderless card-text">
                <video width="100%" height="auto" controls poster={require('./images/vid-poster.png')}>
                  <source src="http://d2j0z7pf0skmgj.cloudfront.net/allclean-clip.mp4" type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              </Card.Text>
            </Card>
            <Card className="clear-card center-card borderless">
                <Card.Body>
                <Button  
                    onClick={() => navigate(`/${CONTACT_US}`)}
                    variant="primary"
                    className="orange-button title-text">REQUEST A CALLBACK</Button>
                </Card.Body>
            </Card>
            <Card className="clear-card center-card borderless">
                    <Card.Text className="title-text orange-font bold">
                        Looking for Reliable, Affordable, Trustworthy Domestic House Cleaners in Wigan and Nearby Areas?
                        <br/>
                    </Card.Text>
            </Card>
            <Card className="clear-card center-card borderless card-space">
                <Card.Text className="title-text blue-font bold">
                     AllClean is your LOCAL, family run domestic house cleaning agency with over 18 years of experience in house cleaning.   Call us on 01942 713111 to discuss your requirements.
                </Card.Text>
            </Card>
            <Card className="clear-card center-card borderless">
                <Card.Text className="title-text orange-font bold">
                    AllClean Domestic Cleaning Agency
                </Card.Text>
            </Card>
            <Card className="clear-card center-card borderless">
                <Card.Text className="title-text">
                No property is too large or too small. Whether you have a 6 bedroom house or a one bedroom flat we can help. <br/>Call 01942 713111 Now
                </Card.Text>
            </Card>
            <Card className="clear-card center-card borderless card-space">
              <Card.Text className="borderless card-text">
                  <Card.Img src={require('./images/interior.jpg')} alt="Card image" style={{maxHeight: '50vh', objectFit: 'cover', minHeight: '12rem'}} />
              </Card.Text>
            </Card>
            <Card className="clear-card center-card borderless">
                <Card.Text className="large-text text-left">
                <div class="ac-list-container">
                  <ul class="ac-list">
                    <li>Maybe you want advice on how best to tackle your regular domestic house cleaning requirements; - you might  have a specific budget you need to work to, or you simply wish to discuss the various options available to you.</li>
                    <li>Without actually visiting your home it is virtually impossible to be able to answer all of your questions accurately.</li>
                    <li>From experience we know our clients prefer to meet someone in person, as they then feel re-assured and know who we are.</li>
                    <li>We tailor your house cleaning service to suit you and your requirements.</li>
                    <li>We will make an appointment to visit at a time convenient for you, answer any questions or concerns you may have, and offer any advice you may need regarding your cleaning requirements.</li>
                  </ul>
                  <p class="ac-list-note">
                    Of course, there is absolutely no obligation or hard sell involved. If at the end of our visit you wish to go ahead we will arrange for one of our cleaners to contact you when it is convenient for you; if you are not sure then we will not contact you again unless you wish us to.
                  </p>
                </div>
                </Card.Text>
            </Card>
            
            <Card className="clear-card center-card borderless">
              <Card.Text className="large-text text-left">
                <div className="container">
                  <div className="image-container">
                    <img src={dontLeaveToChance} alt="Don't leave it to chance" style={{ width: '100%' }} />
                  </div>
                  <div className="text-container">
                    <p class="orange-font bold">Don't Leave it to Chance</p>
                    <p><strong><em>Unlike some Domestic Cleaning Agencies</em></strong> who never meet their clients, we at AllClean realise that you as a customer need to meet and talk to a real human being in person, rather than just listen to a voice over the phone.</p>
                    <Button  
                      onClick={() => navigate(`/${CONTACT_US}`)}
                      variant="primary"
                      className="blue-button button-text bold">Arrange a visit now</Button>
                  </div>
                </div>
              </Card.Text>
            </Card>

            <div class="line"></div>

            <Card className="clear-card center-card borderless">
              <Card.Text className="large-text text-left">
                <div className="container">
                  <div className="text-container">
                    <p class="orange-font bold">Let AllClean take the weight off</p>
                    <p>Fed up of going out to work, driving home, picking the kids up, collecting the shopping and then still having to do all the housework?</p>
                    <p>Wouldn't you rather be doing something you enjoy??</p>
                  </div>
                  <div className="image-container">
                    <img src={takeweightoff} alt="Take the weight off" style={{ width: '100%' }} />
                  </div>
                </div>
              </Card.Text>
            </Card>

            <div class="line"></div>

            <Card className="light-grey-card borderless">
                <FAQ/>
            </Card>
            <Card className="grey-card center-card borderless">
                <Card.Title className="title-text orange-font bold margin-bottom">
                A few testimonials from some of our very happy clients
                </Card.Title>
                <Card.Text>
                    <Testimonials/>
                </Card.Text>
             </Card>

        </div>
    );
};


export default HomeView;