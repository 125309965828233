import React  from "react";
import { useNavigate } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';

const CONTACT_US = 'contact';

const OurServices = () => {

    const navigate = useNavigate();

  return (
        <div class="container mx-auto w-2/3">
            <Card className="bg-dark text-white borderless">
                <Card.Img src={require('./images/oven.png')} alt="Card image" style={{maxHeight: '500px'}} />
                <Card.ImgOverlay className="d-flex align-items-center justify-content-center">
                <Button  
                    onClick={() => navigate(`/${CONTACT_US}`)}
                    variant="primary"
                    className="orange-button button-text">Request a callback</Button>
                </Card.ImgOverlay>
            </Card>
            <Card className="clear-card center-card borderless margin-top">
                <Card.Text className="title-text text-left">
                    <ul class="ac-list">
                        <li>Regular weekly cleaning in Wigan & nearby areas</li>
                        <li>Regular fortnightly cleaning in Wigan & nearby areas</li>
                        <li>Ironing service in your own home</li>
                        <li>One off Spring Cleaning</li>
                        <li>Builders Cleans</li>
                        <li>End of Tenancy /Landlord cleans</li>
                    </ul>
                    <p className="orange-font">
                    Some of the Areas our domestic house cleaning services cover are:- 
                    </p>
                    Wigan, Ince, Aspull, Hindley, Abram, Lowton, Leigh, Pemberton, Orrell, Billinge, Standish, Upholland, Appley Bridge, Shevington, Winstanley, Hawkley Hall, Bryn, Garswood, Ashton-in-Makerfield, Haydock, Newton le Willows,  & more.
                    <p>
                        <p>
                        If your area is not mentioned above please call us to see if we can help you.
                        </p>

                    </p>
                </Card.Text>
            </Card>
        </div>
    );
};


export default OurServices;