/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getEnquiry = /* GraphQL */ `
  query GetEnquiry($id: ID!) {
    getEnquiry(id: $id) {
      id
      firstname
      lastname
      email
      landline
      mobile
      besttimetocall
      comments
      createdAt
      updatedAt
    }
  }
`;
export const listEnquiries = /* GraphQL */ `
  query ListEnquiries(
    $filter: ModelEnquiryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEnquiries(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        firstname
        lastname
        email
        landline
        mobile
        besttimetocall
        comments
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
