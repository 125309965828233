import React from 'react';
import { Nav } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useLocation } from "react-router-dom";
import allCleanLogo from './images/AllClean_logo.ico';
import { FaFacebook, FaTwitter } from 'react-icons/fa';

function Footer() {
  const location = useLocation();
  const isAdmin = location.search.includes("admin");

  return (
    <div className="container mx-auto d-flex align-items-center justify-content-center">
      <div>
        <img src={allCleanLogo} alt="AllClean logo" height={75} width={75} />
      </div>
      <div className="d-flex align-items-center">
        <Nav className="justify-content-center">
          <Nav.Item>
            <Nav.Link as={Link} to="/">Home</Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link as={Link} to="/ourservices">Our Services</Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link as={Link} to="/about">About Us</Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link as={Link} to="/contact">Contact Us</Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link as={Link} to="/faq">FAQ</Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link as={Link} to="/jobs">Jobs</Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link as={Link} to="/terms">Terms</Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link as={Link} to="/privacy">Privacy</Nav.Link>
          </Nav.Item>
          {isAdmin && (
            <Nav.Item>
              <Nav.Link as={Link} to="/enquiries">Enquiries</Nav.Link>
            </Nav.Item>
          )}
        </Nav>
        <div className="d-flex align-items-center ml-3">
          <a href="https://www.facebook.com/allcleanwigan" target="_blank" rel="noopener noreferrer"><FaFacebook size={30} /></a>
          <a href="https://www.twitter.com/allcleanwigan" target="_blank" rel="noopener noreferrer" className="ml-3"><FaTwitter size={30} /></a>
        </div>
      </div>
    </div>
  );
}

export default Footer;
