/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ap-southeast-2",
    "aws_appsync_graphqlEndpoint": "https://jbv345zlxvgihk24dpejpca6ie.appsync-api.ap-southeast-2.amazonaws.com/graphql",
    "aws_appsync_region": "ap-southeast-2",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-nnrrjrsqdjdsxicvgtjerx37te",
    "aws_cognito_identity_pool_id": "ap-southeast-2:9bb05674-cea7-4303-97ad-26842d8366f4",
    "aws_cognito_region": "ap-southeast-2",
    "aws_user_pools_id": "ap-southeast-2_2XvZWPgXU",
    "aws_user_pools_web_client_id": "67o2l5al18duaonruliasj5cq9",
    "oauth": {},
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "allclean9824ed91327a40f4ba8c996412442dcb45927-staging",
    "aws_user_files_s3_bucket_region": "ap-southeast-2"
};


export default awsmobile;
